.jss1 {
    display: flex !important;
    margin-top: 32px !important;
    align-items: center !important;
    flex-direction: column !important;
}

.makeStyles-root-1 {
    display: flex !important;
    margin-top: 32px !important;
    align-items: center !important;
    flex-direction: column !important;
}